import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import "./NavBar.scss";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/carel-logo.png";

const NavBar = () => {
  return (
    <Navbar bg="primary" expand="lg" variant="dark">
      <Container fluid>
        {
          <Link to="/" style={{ textDecoration: "none" }}>
            <img
              src={logo}
              width="150px"
              className="my-2"
              alt="nav logo esmil"
            />
          </Link>
        }
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <a href="#about" style={{ textDecoration: "none" }}>
              <li
                className="mx-3 my-1 text-white"
                style={{ textDecoration: "none" }}
              >
                About Dengue in Sri Lanka
              </li>
            </a>

            <a href="#challenges" style={{ textDecoration: "none" }}>
              <li
                className="mx-3 my-1 text-white"
                style={{ textDecoration: "none" }}
              >
                Challenges
              </li>
            </a>

            <a href="#focus-areas" style={{ textDecoration: "none" }}>
              <li
                className="mx-3 my-1 text-white"
                style={{ textDecoration: "none" }}
              >
                Our Focus Areas
              </li>
            </a>

            <a href="#collaborators" style={{ textDecoration: "none" }}>
              <li
                className="mx-3 my-1 text-white"
                style={{ textDecoration: "none" }}
              >
                Collaborators
              </li>
            </a>
            <a href="#contact" style={{ textDecoration: "none" }}>
              <li
                className="mx-3 my-1 text-white"
                style={{ textDecoration: "none" }}
              >
                Contact
              </li>
            </a>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
