import "./Footer.scss";
import footer_logo from "../../assets/images/carel-logo.png";
const Footer = ()=>{
    return (
        <div className="container-fluid bg-primary footer py-5 border-1">
            
            <div className="container">
            <div className="row text-center">
                <div className="col-md-12">
                    <img src={footer_logo} width="200px" alt="footer esmil logo"/>
                    <p className="text-gray my-3">Center for Applied Research and Enhanced Learning <br/> A registered non-profit organization in Sri Lanka</p>
                    
                  
                </div>
            </div>
            </div>
            
        </div>
    )
}


export default Footer;