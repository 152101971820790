import React from 'react';

import "./shared/bootstrap/bootstrap.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './components/home/Home';


function App() {
  return (
    <BrowserRouter>
      <Routes>
      <Route path="/" element={<Home />} />
      </Routes>
      </BrowserRouter>
  );
}

export default App;
