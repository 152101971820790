import React, { useState } from "react";
import NavBar from "../../shared/NavBar/NavBar";
import Footer from "../../shared/Footer/Footer";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Spinner } from "react-bootstrap";

import Form from "react-bootstrap/Form";
import stats_image_1 from "../../assets/images/Monthly_Comparision.webp";
import stats_image_2 from "../../assets/images/October_2023.webp";

import collab_uom from "../../assets/images/uom.webp";
import collab_uoc from "../../assets/images/uoc.webp";
import collab_moh from "../../assets/images/ministry_of_health.webp";
import collab_deng from "../../assets/images/denguelogo.webp";
import main_banner from "../../assets/images/banner.webp";
import white_paper_image from "../../assets/images/white_paper.webp";
import Dengue_deaths_with_age_gap_image from "../../assets/images/Dengue_deaths_with_age_gap.png";
import Total_reported_cases_in_province_2018_to_2023_image from "../../assets/images/Total_reported_cases_in_province_2018_to_2023.png";
import Cases_distribution_in_2021_image from "../../assets/images/Cases_distribution_in_2021.png";
import Cases_distribution_in_2022_image from "../../assets/images/Cases_distribution_in_2022.png";
import Cases_distribution_in_2023_image from "../../assets/images/Cases_distribution_in_2023.png";

import "./Home.scss";

const Home = () => {
  const [showSpinner, setShowSpinner] = useState(false);
  const handleSpinnerClose = () => setShowSpinner(false);
  const handleSpinnerShow = () => setShowSpinner(true);

  const [showMessageModal, setshowMessageModal] = useState(false);
  const handleMessageModalClose = () => setshowMessageModal(false);
  const handleMessageModalShow = () => setshowMessageModal(true);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [lookingFor, setLookingFor] = useState("");
  const [isInvalidName, setIsInvalidName] = useState(false);
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [isInvalidMessage, setIsInvalidMessage] = useState(false);
  const [isInvalidLookingFor, setIsInvalidLookingFor] = useState(false);
  const [invalidName, setInvalidName] = useState("");
  const [invalidEmail, setInvalidEmail] = useState("");
  const [invalidMessage, setInvalidMessage] = useState("");
  const [invalidLookingFor, setInvalidLookingFor] = useState("");

  const validate = () => {
    let isValid = true;
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!name || name.length < 3) {
      setIsInvalidName(true);
      if (name) {
        setInvalidName("Your name should contain at least 3 characters");
      } else {
        setInvalidName("Your name is required");
      }
      isValid = false;
    } else {
      setIsInvalidName(false);
      setInvalidName("");
    }

    if (!email || !regex.test(email)) {
      setIsInvalidEmail(true);
      if (email) {
        setInvalidEmail("Your email is invalid");
      } else {
        setInvalidEmail("Your email is required");
      }
      isValid = false;
    } else {
      setIsInvalidEmail(false);
      setInvalidEmail("");
    }

    if (!message || message.length < 20) {
      setIsInvalidMessage(true);
      if (message) {
        setInvalidMessage("Your message should at least contain 20 characters");
      } else {
        setInvalidMessage("Your message is required");
      }
      isValid = false;
    } else {
      setIsInvalidMessage(false);
      setInvalidMessage("");
    }

    if (!lookingFor) {
      setIsInvalidLookingFor(true);
      setInvalidLookingFor("This field is required");
      isValid = false;
    } else {
      setIsInvalidLookingFor(false);
      setInvalidLookingFor("");
    }

    return isValid;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const contactUsSendButton: any = document.getElementById(
      "contact-us-send-button"
    );

    if (validate()) {
      handleSpinnerShow();
      const url =
        "https://us-central1-sgx-public-web-communicator.cloudfunctions.net/api/carelHealthContactUs ";
      fetch(url, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Success:", data);
          setInvalidEmail("");
          setInvalidName("");
          setInvalidMessage("");
          setInvalidLookingFor("");
          setEmail("");
          setName("");
          setMessage("");
          setLookingFor("");
          contactUsSendButton.disabled = true;
          handleSpinnerClose();
          handleMessageModalShow();
          let countdownNum = 60;
          incTimer();
          function incTimer() {
            setTimeout(function () {
              if (countdownNum !== 0) {
                countdownNum--;
                contactUsSendButton.textContent =
                  "Send Message: " + countdownNum;
                incTimer();
              } else {
                contactUsSendButton.textContent = "Send Message";
                contactUsSendButton.disabled = false;
              }
            }, 1000);
          }
        })
        .catch((error) => {
          handleSpinnerClose();
          console.error("Error:", error);
        });
    }
  };

  return (
    <>
      <div
        className="container-fluid bg-bg"
        style={{ padding: "0", margin: "0" }}
      >
        <NavBar />

        <section className="p-0 mt-5" id="home" data-aos="fade-up">
          <div className="container">
            <img
              src={main_banner}
              style={{ width: "100%", borderRadius: "20px" }}
              alt="dengue sri lanka"
            />
          </div>
        </section>
        <section className="py-5 " id="about">
          <div className="container">
            <div className="row text-white">
              <div className="col-md-12">
                <p className="h2 mb-4" data-aos="fade-up">
                  About Dengue in Sri Lanka
                </p>
                <p
                  className=""
                  style={{ textAlign: "justify", fontSize: "1em" }}
                  data-aos="fade-up"
                >
                  Dengue, a mosquito-borne viral infection, poses a significant
                  public health concern in many tropical and subtropical regions
                  around the world, including Sri Lanka. It is caused by the
                  Dengue virus, belongs to the Flavivirus family, and has four
                  distinct serotypes. Transmission of the virus to humans occurs
                  through the bites of infected female Aedes mosquitoes.
                </p>

                <p
                  className=""
                  style={{ textAlign: "justify", fontSize: "1em" }}
                  data-aos="fade-up"
                >
                  Dengue is characterized by high fever, headache, retro-orbital
                  pain, joint and muscle pain, and skin rash. It can progress
                  from a mild illness to Dengue Hemorrhagic Fever (DHF) with
                  potential fatalities. Efforts to control the spread of Dengue
                  focus on vector control, public awareness, and preventive
                  measures, especially in high-risk areas. Although there's no
                  specific antiviral treatment, early medical intervention and
                  fluid replacement therapy can mitigate the severity of the
                  disease.
                </p>
              </div>
            </div>

            <div
              className="container bg-primary rounded-5 my-5"
              data-aos="fade-up"
            >
              <section className="container my-4 py-4">
                <div className="container">
                  <div className="row align-items-center">
                    <div
                      className="col-md-4 offset-md-1 order-md-2  aos-init aos-animate "
                      data-aos="fade-left"
                      data-aos-offset="130"
                      data-aos-duration="700"
                      data-aos-once="true"
                    >
                      <img
                        className="network-art img-fluid"
                        src={white_paper_image}
                        alt="Epidemiological Pattern Recognition and Disease Forecasting for Dengue in Sri Lanka"
                        loading="lazy"
                        width={"100%"}
                        style={{ borderRadius: "20px" }}
                      />
                    </div>
                    <div
                      className="col-md-7 order-md-1 align-self-center aos-init aos-animate"
                      data-aos="fade-right"
                      data-aos-offset="80"
                      data-aos-duration="700"
                      data-aos-once="true"
                    >
                      <p className=" fs-4 text-white mt-4 mt-lg-1">
                        Epidemiological Pattern Recognition and Disease
                        Forecasting for Dengue in Sri Lanka
                      </p>
                      <p className=" fs-6  text-white">
                        Explore our latest white paper that delves into the
                        persistent challenge of dengue fever in Sri Lanka. This
                        comprehensive analysis introduces a novel,
                        technology-driven strategy to enhance disease
                        surveillance and optimize healthcare resource
                        allocation. Through a blend of real-time epidemiological
                        data tracking, geospatial outbreak mapping, and
                        predictive analysis, we propose actionable solutions to
                        mitigate the impact of dengue across the most vulnerable
                        segments of the population.
                      </p>

                      <a
                        href="/White_Paper___Epidemiological_Pattern_Recognition_and_Disease_Forecasting_for_Dengue_in_Sri_Lanka.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <button
                          type="button"
                          className="btn btn-primary bg-white text-primary mt-2"
                          id="dtc-button"
                        >
                          View White Paper
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <div className="container" data-aos="fade-up">
              <div className="row">
                <div className="col-md-12 col-lg-6 p-3">
                  <img
                    src={Dengue_deaths_with_age_gap_image}
                    alt="sri lankan dengue stats"
                    className="img-fluid province-images"
                  />
                  <p className="text-center text-white my-3" data-aos="fade-up">
                    Dengue deaths by age category in 2022 and 2023
                  </p>
                </div>

                <div className="col-md-12 col-lg-6 p-3">
                  <img
                    src={Total_reported_cases_in_province_2018_to_2023_image}
                    alt="sri lankan dengue stats"
                    className="img-fluid province-images"
                  />
                  <p className="text-center text-white my-3" data-aos="fade-up">
                    Dengue cases from 2018 to 2023 for each province
                  </p>
                </div>
              </div>
            </div>

            <div className="container mt-3" data-aos="fade-up">
              <div className="row">
                <div className="col-md-12 col-lg-4 p-3">
                  <img
                    src={Cases_distribution_in_2021_image}
                    alt="sri lankan dengue stats"
                    className="img-fluid"
                    style={{
                      objectFit: "fill",
                      borderRadius: "20px",
                      width: "100%",
                    }}
                  />
                </div>

                <div className="col-md-12 col-lg-4 p-3 ">
                  <img
                    src={Cases_distribution_in_2022_image}
                    alt="sri lankan dengue stats"
                    className="img-fluid"
                    style={{
                      objectFit: "fill",
                      borderRadius: "20px",
                      width: "100%",
                    }}
                  />
                </div>

                <div className="col-md-12 col-lg-4 p-3">
                  <img
                    src={Cases_distribution_in_2023_image}
                    alt="sri lankan dengue stats"
                    className="img-fluid"
                    style={{
                      objectFit: "fill",
                      borderRadius: "20px",
                      width: "100%",
                    }}
                  />
                </div>
                <p className="text-center text-white my-3" data-aos="fade-up">
                  Dengue distribution by district from 2021 to 2023
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="mt-0" id="challenges">
          <div className="container">
            <div className="row text-white">
              <div className="col-md-12">
                <p className="h2 mb-4" data-aos="fade-up">
                  Challenges
                </p>

                <p
                  className=""
                  style={{ textAlign: "justify", fontSize: "1em" }}
                  data-aos="fade-up"
                >
                  Dengue is characterized by high fever, headache, retro-orbital
                  pain, joint and muscle pain, and skin rash. It can progress
                  from a mild illness to Dengue Hemorrhagic Fever (DHF) with
                  potential fatalities. Efforts to control the spread of Dengue
                  focus on vector control, public awareness, and preventive
                  measures, especially in high-risk areas. Although there's no
                  specific antiviral treatment, early medical intervention and
                  fluid replacement therapy can mitigate the severity of the
                  disease.
                </p>

                <p
                  className=""
                  style={{ textAlign: "justify", fontSize: "1em" }}
                  data-aos="fade-up"
                >
                  The tropical climate of Sri Lanka with its heavy rainfall,
                  humidity, and warm temperatures creates an ideal environment
                  for mosquitoes to breed year-round. While progress has been
                  made in controlling dengue, some key challenges that are
                  prevailing can be identified as:
                </p>

                <ul data-aos="fade-up">
                  <li>
                    Resource Limitations: Limited resources in terms of funding,
                    manpower, and technical expertise can impede sustained and
                    effective Dengue control programs.
                  </li>
                  <li>
                    Monitoring and Surveillance: Adequate monitoring and
                    surveillance systems are needed to predict and respond to
                    Dengue outbreaks promptly.
                  </li>
                  <li>
                    Changing Dengue Virus Strains: The evolution and
                    introduction of new Dengue virus strains can lead to
                    increased severity of outbreaks and challenges in developing
                    effective vaccines and treatments.
                  </li>
                  <li>
                    Public Awareness and Participation: There is a need for
                    increased public awareness and community participation in
                    preventive measures. Misconceptions and lack of knowledge
                    about Dengue prevention can hinder efforts to control the
                    spread.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="mt-3" id="focus-areas">
          <div className="container">
            <div className="row text-white">
              <div className="col-md-12">
                <p className="h2 mb-4" data-aos="fade-up">
                  Our Focus Areas
                </p>
                <p className="h5 mb-4" data-aos="fade-up">
                  Summary of the work we are doing right now
                </p>

                <div>
                  <p
                    className=""
                    style={{ textAlign: "justify", fontSize: "1.1em" }}
                    data-aos="fade-up"
                  >
                    Disease Surveillance and Vector Surveillance
                  </p>
                  <ul data-aos="fade-up">
                    <li className="mt-1">
                      <strong>Real-time Epidemiological Data Tracking:</strong>{" "}
                      Implementing systems to monitor and report Dengue cases as
                      they occur.
                    </li>
                    <li className="mt-1">
                      <strong>Geospatial Mapping of Outbreaks:</strong>{" "}
                      Utilizing GIS technology to track and predict outbreak
                      hotspots.
                    </li>
                    <li className="mt-1">
                      <strong>Sociodemographic Data Analysis:</strong> Analyzing
                      available data to identify dengue prevalence, seasonal
                      variations, and high-risk populations impacting
                      disease-related mortality and morbidity.
                    </li>
                    <li className="mt-1">
                      <strong>Epidemiological Pattern Recognition:</strong>{" "}
                      Analyzing disease variations and seasonal fluctuations of
                      vector populations to recognize disease patterns and
                      occurrence of outbreaks to develop models for pattern
                      recognition and prediction.
                    </li>
                    <li className="mt-1">
                      <strong>Models for Preventive Care:</strong> Analyzing
                      patient data to identify patients/communities at risk of
                      Dengue Hemorrhagic Fever
                    </li>
                    <li className="mt-1">
                      <strong>
                        Identification of Prevailing Preventive Care:
                      </strong>{" "}
                      Recognizing various modes of preventive care, including
                      vector control programs, the effectiveness of Integrated
                      Vector Management (IVM) strategies, and community health
                      educational programs while identifying lapses in
                      pre-exposure medication and vaccination.
                    </li>
                    <li className="mt-1">
                      <strong>Identify Key Players in Preventive Care: </strong>
                      Identification of areas with gaps or deficiencies in
                      preventive care and key players to facilitate
                      collaboration and coordination for more effective
                      preventive measures.
                    </li>
                  </ul>
                </div>

                <div>
                  <p
                    className=""
                    style={{ textAlign: "justify", fontSize: "1.1em" }}
                    data-aos="fade-up"
                  >
                    Data-Driven Patient Care and Resource Allocation
                  </p>
                  <ul data-aos="fade-up">
                    <li className="mt-1">
                      <strong>Healthcare Resource Optimization: </strong> Using
                      data analytics to allocate medical resources efficiently
                      during outbreaks.
                    </li>
                    <li className="mt-1">
                      <strong>Community Outreach Programs:</strong> Tailoring
                      awareness and prevention programs based on demographic and
                      regional data.
                    </li>
                  </ul>
                </div>

                <div>
                  <p
                    className=""
                    style={{ textAlign: "justify", fontSize: "1.1em" }}
                    data-aos="fade-up"
                  >
                    Deep Learning-Based Research for Antibody Generation
                  </p>
                  <ul data-aos="fade-up">
                    <li className="mt-1">
                      <strong>
                        Modeling Protein Sequences and Structures:
                      </strong>{" "}
                      Utilizing computer models to capture the sequence and
                      structure of virus proteins and antibodies.
                    </li>
                    <li className="mt-1">
                      <strong>
                        Development of Binding Affinity Prediction Models:{" "}
                      </strong>
                      Creating advanced models to predict the binding affinity
                      between antibodies and antigens accurately.
                    </li>
                    <li className="mt-1">
                      <strong>Monoclonal Antibody Therapy Innovations: </strong>{" "}
                      Researching new approaches for monoclonal antibody
                      therapies specifically targeting Dengue virus strains.
                    </li>
                    <li className="mt-1">
                      <strong>
                        Targeted Mutations in Antibody Generation:
                      </strong>{" "}
                      Employing deep learning to identify and generate effective
                      mutations in antibodies for enhanced Dengue
                      neutralization.
                    </li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.carelweb.org/projects/Deep-Geometric-Framework-to-Predict-Antibody-Antigen-Binding-Affinity?id=8"
                    >
                      <p></p>
                      <li
                        className="mt-1"
                        style={{ textDecoration: "underline" }}
                      >
                        Read more
                      </li>
                    </a>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="mt-5 mb-5 " id="collaborators">
          <div className="container">
            <div className="row text-white">
              <div className="col-md-12">
                <p className="h2 text-center mb-4" data-aos="fade-up">
                  Collaborators
                </p>

                <p className="h4 text-center mb-4" data-aos="fade-up">
                  Information about collaborating institutions and researchers.
                </p>
              </div>
            </div>

            <div className="row text-white pt-1 justify-content-between py-5">
              <div className="col-md-3 my-2 col-12 research-area-card ">
                <div
                  className="card bg-dark-bg rounded h-100"
                  data-aos="fade-right"
                  data-aos-delay="300"
                >
                  <div className="row align-items-center justify-content-center p-4">
                    <img
                      src={collab_uom}
                      style={{ height: "180px", width: "auto" }}
                      alt="uom"
                    />
                    <p className="h5 pt-4 text-center text-white">
                      University of Moratuwa
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 my-2 col-12 research-area-card">
                <div
                  className="card bg-dark-bg rounded h-100"
                  data-aos="fade-right"
                  data-aos-delay="400"
                >
                  <div className="row  align-items-center justify-content-center p-4">
                    <img
                      alt="uoc"
                      src={collab_uoc}
                      className="p-2"
                      style={{ height: "180px", width: "auto" }}
                    />
                    <p className="h5 pt-4 text-center text-white">
                      University of Colombo
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 my-2 col-12 research-area-card ">
                <div
                  className="card bg-dark-bg rounded h-100"
                  data-aos="fade-right"
                  data-aos-delay="500"
                >
                  <div className="row align-items-center justify-content-center p-4">
                    <img
                      alt="dengue"
                      src={collab_deng}
                      className="pt-3 p-3"
                      style={{ height: "170px", width: "auto" }}
                    />
                    <p className="h5 pt-4 text-center text-white">
                      National Dengue <br></br> Control Unit
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 my-2 col-12 research-area-card ">
                <div
                  className="card bg-dark-bg rounded h-100"
                  data-aos="fade-right"
                  data-aos-delay="600"
                >
                  <div className="row align-items-center justify-content-center p-4">
                    <img
                      alt="moh"
                      src={collab_moh}
                      className="p-2"
                      style={{ height: "180px", width: "auto" }}
                    />
                    <p className="h5 pt-4 text-center text-white">
                      Ministry of Health
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="py-5 bg-primary" id="contact">
          <div className="container py-1" data-aos="fade-up">
            <p
              className="h2 text-center text-white mb-2 mt-0"
              id="contact_section"
            >
              Contact
            </p>
            <p
              className="text-white  text-center my-4"
              style={{ fontWeight: "100", fontSize: "1.1em" }}
            >
              We are always open to challenging problems and exceptional talent.
              If you have a problem we can work on, or you think you are a good
              fit for our team, hit us up!
            </p>
            <div className="container contact-form align-items-center">
              <div className="my-2 py-3">
                <div className="text-white">
                  <Form
                    className="needs-validation"
                    validated={false}
                    onSubmit={handleSubmit}
                  >
                    <div className="row align-items-start">
                      <div className="col-md-6">
                        <Form.Group className="mb-3" controlId="formBasicName">
                          <Form.Control
                            type="text"
                            placeholder="Your Name"
                            name="name"
                            value={name}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setName(e.target.value);
                            }}
                          />
                          {isInvalidName ? (
                            <p className="text-danger">{invalidName}</p>
                          ) : (
                            <></>
                          )}
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Control
                            type="email"
                            placeholder="Your Email"
                            name="email"
                            value={email}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setEmail(e.target.value);
                            }}
                          />
                          {isInvalidEmail ? (
                            <p className="text-danger">{invalidEmail}</p>
                          ) : (
                            <></>
                          )}
                        </Form.Group>
                        <Form.Group
                          className="mb-3 d-flex flex-wrap"
                          controlId="formBasicCheckbox"
                        >
                          <Form.Check
                            type="radio"
                            name="lookingFor"
                            label="Looking to collaborate"
                            className="mx-2"
                            id="1"
                            value={`Looking to collaborate`}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setLookingFor(e.target.value);
                            }}
                            checked={lookingFor === `Looking to collaborate`}
                          />
                          <Form.Check
                            type="radio"
                            name="lookingFor"
                            label="Looking to join the team"
                            className="mx-2"
                            id="2"
                            value={`Looking to join the team`}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setLookingFor(e.target.value);
                            }}
                            checked={lookingFor === `Looking to join the team`}
                          />
                          {isInvalidLookingFor ? (
                            <p className="text-danger">{invalidLookingFor}</p>
                          ) : (
                            <></>
                          )}
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Control
                            as="textarea"
                            rows={5}
                            placeholder="Your Message"
                            name="message"
                            value={message}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setMessage(e.target.value);
                            }}
                          />
                          {isInvalidMessage ? (
                            <p className="text-danger">{invalidMessage}</p>
                          ) : (
                            <></>
                          )}
                        </Form.Group>
                      </div>
                    </div>
                    <div className=" d-flex align-items-center justify-content-center">
                      <button
                        className="btn btn-primary send-btn mt-2"
                        type="submit"
                        id="contact-us-send-button"
                      >
                        {" "}
                        Send Message
                      </button>
                    </div>
                  </Form>

                  {/*  {!showBacktoTop && (
                   
                      <button className="btn btn-primary back-to-top-button "  onClick={scrollToTop}>
                        <img src={back_top_icon} alt="back to top"/>
                      </button>
                  
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* spinner */}
        <div id="spinner_modal">
          <Modal
            show={showSpinner}
            onHide={handleSpinnerClose}
            animation={true}
            centered
            backdrop="static"
          >
            <Modal.Body className="text-center p-5">
              <Spinner animation="border" variant="primary" />
              <p className="lead mt-2">Loading... Please Wait</p>
            </Modal.Body>
          </Modal>
        </div>

        {/* message modal */}
        <div id="message_modal">
          <Modal
            show={showMessageModal}
            onHide={handleMessageModalClose}
            animation={true}
            centered
          >
            <Modal.Body className="text-center p-3">
              <p className="lead mt-2">Your message as been sent.</p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                className="text-white"
                onClick={handleMessageModalClose}
              >
                Okay
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Home;
